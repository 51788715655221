<template>
    <div class="wrapper -sala-de-prensa">

        <div class="encabezado">
            <div class="container">
                <h2>Sala de prensa</h2>
            </div>
        </div>


        <div class="contenedor-salas container row-center">

            <main>

                <div class="filtro">
                    <div class="contenedor-input">
                        <input type="text" placeholder="Buscar noticias" v-model="filtro.text">
                    </div>
                    <div class="contenedor-input">
                        <select v-model="filtro.categoria">
                            <option value="">Categoría</option>
                            <option :value="cat.slug" :key="key" v-for="(cat,key) in categories">{{cat.name}}</option>
                        </select>
                    </div>

                    <div class="contenedor-fechas">
                        <datepicker v-model="filtro.date" :name="'date'"></datepicker>
                    </div>

                </div>

                <div class="contenedor-noticias">
                    <noticia :data="n" :key="key" v-for="(n,key) in filteredEntries"></noticia>
                </div>

            </main>

            <aside class="contacto-prensa">
                <h2>Contacto de prensa</h2>
                <h3>Silvia Cano Villalba</h3>
                <h4>Responsable de Comunicación y Prensa</h4>
                <p>C/ Sepúlveda, 11</p>
                <p>28108 Alcobendas</p>
                <p>Madrid - España</p>
                <p>Telefono <a href="tel:637089687">+34 637 089 688</a></p>
                <p><a href="mailto:silvia.cano@continental.com">silvia.cano@continental.com</a></p>
            </aside>

        </div>




    </div>



</template>


<script>

    import noticia from '@/components/noticia-el';

    import datepicker from '@/components/datepicker-multi';

    import { mapActions } from 'vuex';

    export default {
        name: 'sala-de-prensa',
        mounted() {
            this.setData(this.filtro.categoria);
        },
        data() {
            return {
                showmore: false,
                entries: '',
                filtro: {
                    date: '',
                    text: '',
                    searchIn: ['title', 'header'],
                    categoria : ''
                },
            }
        },
        methods: {
            ...mapActions(['requestEntries', 'requestCategories']),
            setData(value) {
                this.requestEntries(value).then(r => {
                    this.entries = r.data;
                });
            }
        },
        computed: {
            filteredEntries() {

                let filtered;

                if (this.entries.length != 0) {

                    filtered = this.entries.slice();


                    if(this.filtro.categoria){
                        filtered = filtered.filter(n => {
                            return (n.categories.some(c => c.slug ))
                        });
                    }



                    if (this.filtro.date) {
                        let min = new Date(this.filtro.date.start).getTime();
                        let max = (this.filtro.date.end) ? new Date(this.filtro.date.end).getTime() : new Date();

                        filtered = filtered.filter(n => {
                            let date = new Date(n.created_at).getTime();
                            return (date >= min && date <= max);
                        });
                    }

                    if (this.filtro.text) {

                        filtered = filtered.filter(n => {

                            let can = false;

                            this.filtro.searchIn.forEach(e => {
                                if (n[e].toLowerCase().includes(this.filtro.text.toLowerCase())) {
                                    can = true;
                                }
                            });

                            return can;

                        })

                    }


                }

                return filtered;
            },
            categories() {
                let categories = new Array();

                if (this.entries.length != 0) {

                    this.entries.forEach(e => {
                        e.categories.forEach(c => {

                            console.log(c.slug);

                            if (!categories.some(s =>  (s.slug == c.slug ))) {
                                categories.push(c);
                            }
                        })
                    })

                }

                return categories;
            }
        },
        components: {
            'noticia': noticia,
            'datepicker': datepicker
        },
        watch: {
            'filtro.categoria'(value) {
                this.setData(value);
            }
        }
    }
</script>